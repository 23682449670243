import { Button, ButtonBase } from "@material-ui/core";
import { ArrowRightAlt } from "@material-ui/icons";
import { graphql, Link } from "gatsby";
import { IGatsbyImageData, GatsbyImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import Masonry from "react-masonry-css";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { cleanImgBase, getSingleImageBySrc, Images } from "../utils/findImage";

import ReactPlayer from "react-player/lazy";
import homepageVideo from "../videos/homepage-video.mp4";

interface HomepageProps {
  location: Location;
  data: {
    bgImage: {
      gatsbyImageData: IGatsbyImageData;
    };
    allImages: Images;
    allIcons: Images;
    projectImages: Images;
  };
}

const Homepage: React.FC<HomepageProps> = ({ data, location }) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  return (
    <Layout>
      <SEO
        title="Professional Painting Services Charlotte, NC"
        description="Get a beautiful new look in your home or business. We are a residential and commercial painting company serving Charlotte area for over 15 years."
        slug={location.pathname}
      />

      <section className="relative py-0">
        <div className="absolute left-0 bottom-0 w-full h-full max-h-[40%] bg-gradient z-20" />

        <div className="relative w-full h-[300px] sm:h-[40vh] md:h-[60vh] xl:h-[80vh] z-0">
          {!isVideoPlaying && (
            <GatsbyImage
              image={data.bgImage.gatsbyImageData}
              alt="Noda Painting"
              objectFit="cover"
              objectPosition="center center"
              className="h-full w-full"
              loading="eager"
            />
          )}
          <ReactPlayer
            playing
            muted
            loop
            className="w-full h-full z-0 object-cover relative reactPlayer"
            width="100%"
            height="100%"
            style={{ objectFit: "cover" }}
            url={homepageVideo}
            onReady={() => setIsVideoPlaying(true)}
          />
        </div>
      </section>

      <section className="py-0">
        <ServiceRow
          backgroundImage={getSingleImageBySrc(
            "kitchen-cabinets-painting-3",
            data.allImages
          )}
          backgroundPosition="center 82%"
          icon={getSingleImageBySrc("kitchen-cabinets", data.allIcons)}
          title="Kitchen cabinets"
          to="/kitchen-cabinets/"
          cardPosition="flex-row-reverse"
        >
          Get a beautiful new look for your kitchen with high-quality, durable
          coatings for your cabinets or built-ins.
        </ServiceRow>

        <ServiceRow
          backgroundImage={getSingleImageBySrc(
            "exterior-painting-8",
            data.allImages
          )}
          backgroundPosition="center 65%"
          icon={getSingleImageBySrc("residential.png", data.allIcons)}
          title="Residential"
          to="/residential/"
          cardPosition=""
        >
          We offer a wide range of premium interior and exterior painting
          services to make your home feel brand new.
        </ServiceRow>

        <ServiceRow
          backgroundImage={getSingleImageBySrc(
            "commercial-painting-2",
            data.allImages
          )}
          backgroundPosition="center 65%"
          icon={getSingleImageBySrc("commercial.png", data.allIcons)}
          title="Commercial"
          to="/commercial/"
          cardPosition="flex-row-reverse"
        >
          We offer the highest quality painting services for businesses and
          contractors in Charlotte, NC, with over 15 years of experience.
        </ServiceRow>
      </section>

      <section className="container">
        <div>
          <div className="text-center max-w-3xl mx-auto">
            <h2 className="text-3xl md:text-4xl font-bold">
              About us &amp; our work
            </h2>
            <p className="mb-4">
              We've been providing Charlotte, NC, with professional residential
              and commercial painting for over 15 years. At NoDa Painting, we're
              committed to delivering the finest craftsmanship with the highest
              quality finishes available.
            </p>
            <Button
              component={Link}
              to="/about/"
              variant="outlined"
              color="primary"
              size="large"
              disableElevation
            >
              Learn more
            </Button>
          </div>
        </div>
      </section>

      <section className="py-0">
        <div>
          <Masonry
            breakpointCols={{ default: 4, 640: 1, 1024: 2 }}
            className="col-span-full flex flex-wrap"
            columnClassName="px-0.5 bg-clip-padding"
          >
            {data.projectImages.nodes.map((node, index) => (
              <div key={`projectImage-${index}`} className="mb-1">
                <GatsbyImage
                  image={node.childImageSharp.gatsbyImageData}
                  alt={cleanImgBase(node.base)}
                />
              </div>
            ))}
          </Masonry>
        </div>
      </section>

      <section className="container">
        <div>
          <div className="text-center max-w-3xl mx-auto">
            <h2 className="text-3xl md:text-4xl font-bold mb-8">
              Ready to talk about your painting project?
            </h2>

            <Button
              component={Link}
              to="/contact/"
              variant="outlined"
              color="primary"
              size="large"
              disableElevation
            >
              Get your free estimate!
            </Button>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Homepage;

export const pageQuery = graphql`
  query {
    bgImage: imageSharp(original: { src: { regex: "/video-cover/" } }) {
      id
      gatsbyImageData(layout: FULL_WIDTH)
    }
    allImages: allFile(
      filter: {
        base: {
          in: [
            "kitchen-cabinets-painting-3.jpg"
            "exterior-painting-8.jpg"
            "commercial-painting-2.jpg"
          ]
        }
      }
    ) {
      nodes {
        relativePath
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 90)
          original {
            src
          }
        }
        base
      }
    }
    allIcons: allFile(filter: { relativeDirectory: { eq: "icons" } }) {
      nodes {
        relativePath
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 48, height: 48)
          original {
            src
          }
        }
        base
      }
    }
    projectImages: allFile(
      filter: {
        base: {
          in: [
            "interior-painting-1.jpg"
            "kitchen-cabinets-painting-1.jpg"
            "kitchen-cabinets-painting-4.jpg"
            "kitchen-cabinets-painting-2.jpg"
          ]
        }
      }
    ) {
      nodes {
        relativePath
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 90)
          original {
            src
          }
        }
        base
      }
    }
  }
`;

interface ServiceRowProps {
  backgroundImage?: IGatsbyImageData;
  backgroundPosition: string;
  icon?: IGatsbyImageData;
  title: string;
  to: string;
  cardPosition: string;
}

const ServiceRow: React.FC<ServiceRowProps> = ({
  backgroundImage,
  backgroundPosition,
  icon,
  title,
  to,
  cardPosition,
  children,
}) => {
  return (
    <div className="relative">
      <div className={`flex ${cardPosition} w-full h-[475px]`}>
        <div
          className={`relative hidden sm:block h-full w-full sm:w-1/2 lg:w-8/12 top-0`}
        >
          {backgroundImage ? (
            <GatsbyImage
              image={backgroundImage}
              alt={title}
              objectFit="cover"
              objectPosition={backgroundPosition}
              className="w-full h-full"
            />
          ) : null}
        </div>
        <ButtonBase
          component={Link}
          to={to}
          className={`flex flex-col flex-grow items-center justify-center bg-primary-dark border-b border-solid border-primary-light/40 sm:border-0 h-full w-full sm:w-1/2 lg:w-4/12 text-center ${cardPosition}`}
        >
          <div className="mb-4">
            {icon ? <GatsbyImage image={icon} alt={title} /> : null}
          </div>
          <div className="px-4 max-w-sm text-white">
            <h3 className="text-white text-2xl md:text-3xl font-bold mb-4">
              {title}
            </h3>
            <p className="mb-4">{children}</p>
            <div>
              <ArrowRightAlt fontSize="large" className="text-white" />
            </div>
          </div>
        </ButtonBase>
      </div>
    </div>
  );
};
